<template>
  <div class="private-goods-page">
    <market-header @change="headerParamChange" :search="market_header_search" />

    <en-table-layout
      ref="tableLayout"
      :tableData="pageData.data"
      :loading="loading"
      :toolbar-two="true"
      :toolbar-three="params.self_goods === 2 ? true : false"
      :redundancy-height="49"
      @selection-change="handleSelectionChange"
      @sort-change="handleSortChange"
    >
      <template slot="toolbar" v-if="params.self_goods === 2">
        <el-row>
          <el-col :span="6">
            <el-form-item label="会员价">
              <el-input
                style="width: 75px;"
                size="medium"
                :controls="false"
                v-model="params.low_price"
              ></el-input>
              <span style="margin: 0 4px;">-</span>
              <el-input
                style="width: 75px;"
                size="medium"
                :controls="false"
                v-model="params.high_price"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="利润率(%)">
              <el-input
                style="width: 75px;"
                size="medium"
                :controls="false"
                v-model="params.profit_min"
              ></el-input>
              <span style="margin: 0 4px;">-</span>
              <el-input
                style="width: 75px;"
                size="medium"
                :controls="false"
                v-model="params.profit_max"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item>
              <el-input v-model="searchContent" placeholder="请输入 商品名称 或 供应商名称" style="width: 320px">
                <el-select style="width: 90px;" slot="prepend" v-model="key_word">
                  <el-option label="商品" value="goods_name"></el-option>
                  <el-option label="供应商" value="seller_name"></el-option>
                </el-select>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-button @click="searchEvent('')" size="small" type="primary">搜索</el-button>
            <el-button @click="selectGoodsAll" size="small" type="primary">批量挑选</el-button>
          </el-col>
        </el-row>

        <!-- <div class="col"></div> -->
        <!-- <div >
          <el-button @click="searchEvent('')" size="small" type="primary">筛选</el-button>
          <el-button @click="selectGoodsAll" size="small" type="primary">批量挑选</el-button>
          <el-button @click="addShop" size="small" v-show="params.self_goods===1" type="primary">录入商品</el-button>
        </div>-->
        <!-- 为京东馆 -->
        <!-- <template v-if="canshu.self_goods=='2'">
          <div class="conditions">
            <span>价格：</span>
            <el-input style="width: 60px;" size="medium" :controls="false" v-model="params.min"></el-input>
            <span style="margin: 0 4px;">-</span>
            <el-input style="width: 60px;" size="medium" :controls="false" v-model="params.max"></el-input>
          </div>
        </template>-->
        <!-- <el-button @click="gotoRecycle" size="small" type="primary">回收站</el-button> -->
        <!-- <el-button @click="underBatch" size="small" type="danger">批量下架</el-button> -->
      </template>

      <template slot="toolbar" v-if="params.self_goods !== 2">
        <el-row>
          <el-col :span="7">
            <el-form-item label="会员价">
              <el-input
                style="width: 75px;"
                size="medium"
                :controls="false"
                v-model="params.low_price"
              ></el-input>
              <span style="margin: 0 4px;">-</span>
              <el-input
                style="width: 75px;"
                size="medium"
                :controls="false"
                v-model="params.high_price"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item>
              <el-input v-model="searchContent" placeholder="请输入 商品名称 或 供应商名称" style="width: 320px">
                <el-select style="width: 90px;" slot="prepend" v-model="key_word">
                  <el-option label="商品" value="goods_name"></el-option>
                  <el-option label="供应商" value="seller_name"></el-option>
                </el-select>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-button @click="searchEvent('')" size="small" type="primary">搜索</el-button>
            <el-button @click="selectGoodsAll" size="small" type="primary">批量挑选</el-button>
            <el-button
              @click="addShop"
              size="small"
              v-show="params.self_goods===1"
              type="primary"
            >录入商品</el-button>
          </el-col>
        </el-row>
      </template>

      <template slot="toolbar-two" v-if="params.self_goods === 2">
        <el-row>
          <el-col :span="6">
            <el-form-item label="市场价">
              <el-input
                style="width: 75px;"
                size="medium"
                :controls="false"
                v-model="params.low_mktprice"
              ></el-input>
              <span style="margin: 0 4px;">-</span>
              <el-input
                style="width: 75px;"
                size="medium"
                :controls="false"
                v-model="params.high_mktprice"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item v-if="params.self_goods === 2" label="商品配送类型">
              <el-select
                style="width: 145px"
                v-model="params.ship_method"
                placeholder="请选择配送类型"
                clearable
              >
                <el-option label="全部" value />
                <el-option label="由厂家配送" :value="2" />
                <el-option label="京东自营配送" :value="1" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="商品分类">
              <el-cascader
                clearable
                style="width:250px"
                v-model="category"
                :options="categoryProxy.children"
                :props="categoryProxy.props"
                @change="selectChange"
              />
            </el-form-item>
          </el-col>
          <el-col :span="5"></el-col>
        </el-row>
      </template>

      <template slot="toolbar-two" v-if="params.self_goods !== 2">
        <el-row>
          <el-col :span="7">
            <el-form-item label="市场价">
              <el-input
                style="width: 75px;"
                size="medium"
                :controls="false"
                v-model="params.low_mktprice"
              ></el-input>
              <span style="margin: 0 4px;">-</span>
              <el-input
                style="width: 75px;"
                size="medium"
                :controls="false"
                v-model="params.high_mktprice"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="商品分类">
              <el-cascader
                clearable
                style="width:250px"
                v-model="category"
                :options="categoryProxy.children"
                :props="categoryProxy.props"
                @change="selectChange"
              />
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="利润率(%)">
              <el-input
                style="width: 75px;"
                size="medium"
                :controls="false"
                v-model="params.profit_min"
              ></el-input>
              <span style="margin: 0 4px;">-</span>
              <el-input
                style="width: 75px;"
                size="medium"
                :controls="false"
                v-model="params.profit_max"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </template>

      <template slot="toolbar-three" v-if="params.self_goods === 2">
        <el-row>
          <el-col :span="24">
            <div class="address-border">
              <el-form-item label="显示类型">
                <el-select
                  size="small"
                  class="choose-machine center"
                  v-model="areaSelectData.jd_area_state"
                  placeholder="请选择"
                  style="width:200px;"
                  :disabled="areaSelectData.province===''"
                >
                  <el-option label="全部" :value="0"></el-option>
                  <el-option label="有货" :value="1"></el-option>
                  <el-option label="无货" :value="2"></el-option>
                </el-select>
              </el-form-item>
            <el-form-item label="地区" :style="{width: areaNum * 120+ 60 +'px'}">
                <el-select
                  size="small"
                  class="choose-machine center"
                  v-model="areaSelectData.province"
                  placeholder="省/直辖市"
                  style="width:110px;margin-right: 10px"
                  clearable
                >
                  <el-option
                    v-for="(item,index) in this.provinceList"
                    :label="item.name"
                    :value="item.id"
                    :key="index"
                  ></el-option>
                </el-select>
                <el-select
                  v-if="areaNum > 1"
                  size="small"
                  class="choose-machine center"
                  v-model="areaSelectData.city"
                  placeholder="城市/地区"
                  style="width:110px;margin-right: 10px"
                  clearable
                >
                  <el-option
                    v-for="(item,index) in this.cityList"
                    :label="item.name"
                    :value="item.id"
                    :key="index"
                  ></el-option>
                </el-select>
                <el-select
                  v-if="areaNum > 2"
                  size="small"
                  class="choose-machine center"
                  v-model="areaSelectData.county"
                  placeholder="县/区"
                  style="width:110px;margin-right: 10px"
                  clearable
                >
                  <el-option
                    v-for="(item,index) in this.countyList"
                    :label="item.name"
                    :value="item.id"
                    :key="index"
                  ></el-option>
                </el-select>
                <el-select
                  v-if="areaNum > 3 && this.townList.length > 0"
                  size="small"
                  class="choose-machine center"
                  v-model="areaSelectData.town"
                  placeholder="乡镇/街道"
                  style="width:110px;margin-right: 10px"
                  clearable
                >
                  <el-option
                    v-for="(item,index) in this.townList"
                    :label="item.name"
                    :value="item.id"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
      </template>

      <template slot="table-columns">
        <el-table-column :key="'selection'" type="selection" />
        <el-table-column label="图片" width="60" fixed="left" class-name="goods-cover-wrapper">
          <template slot-scope="{row}">
            <el-popover placement="right" trigger="hover">
              <img :src="row.thumbnail" alt style="width: 300px" />

              <router-link :to="'/markets/good?from=0&goods_id=' + row.goods_id" slot="reference">
                <img :src="row.thumbnail" class="goods-cover" alt />
                <img class="goods-tag" :src="getRowTagImage(row)" alt />
              </router-link>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column label="商品名称" min-width="200" :show-overflow-tooltip="true" fixed="left">
          <template
            slot-scope="{row}"
          >{{ row.goods_name.replaceAll('amp;','').replaceAll(' ','&nbsp;') }}</template>
        </el-table-column>

        <el-table-column key="good_sn" width="120" label="商品编号">
          <template slot-scope="scope">{{ scope.row.good_sn }}</template>
        </el-table-column>

        <el-table-column :key="'mktprice'" label="市场价" width="120">
          <template slot-scope="scope">
            <template>
              <span v-if="scope.row.goods_type === 'POINT'">
                {{ scope.row.exchange_money | unitPrice('￥') }} +
                {{ scope.row.exchange_point ? scope.row.exchange_point : 0 }}
                积分
              </span>
              <span v-else>{{ scope.row.mktprice | unitPrice('￥') }}</span>
            </template>
          </template>
        </el-table-column>

        <el-table-column :key="'price'" label="会员价" width="140" sortable="custom" prop="price">
          <template slot-scope="scope">
            <span v-if="scope.row.goods_type === 'POINT'">
              {{ scope.row.exchange_money | unitPrice('￥') }} +
              {{ scope.row.exchange_point || 0 }}
              积分
            </span>
            <span v-else>{{ scope.row.price | unitPrice('￥') }}</span>
          </template>
        </el-table-column>

        <el-table-column label="所属供应商" width="100" show-overflow-tooltip v-if="params.self_goods === 3 && $store.getters.isSupplierName">
          <template slot-scope="scope">
            {{ scope.row.seller_name }}
          </template>
        </el-table-column>

        <!-- <el-table-column :key="Math.random()" label="成本价" width="120" v-if="canshu.self_goods=='2'">
          <template slot-scope="scope">{{ scope.row.price | unitPrice('￥') }}</template>
        </el-table-column>-->

        <el-table-column label="利润率" width="100" sortable="custom" prop="profit">
          <template slot-scope="scope">{{ (scope.row.enterprise_profit || 0) | unitCompare('%') }}</template>
        </el-table-column>

        <el-table-column label="上架时间" width="130" prop="time" sortable="custom">
          <template slot-scope="scope">{{ scope.row.up_time | unixToDate('yyyy-MM-dd hh:mm') }}</template>
        </el-table-column>

        <el-table-column label="库存" width="100">
          <!-- 商品来源，0普通商品，1京东商品，2苏宁商品 -->
          <template slot-scope="scope">
            <template v-if="quantityStatus">{{scope.row.stock_state_desc}}</template>
            <template
              v-else-if="scope.row.goods_source === 1"
            >{{ scope.row.quantity > 0 ? '有货' : '无货' }}</template>
            <template v-else>{{ scope.row.quantity }}件</template>
          </template>
        </el-table-column>

        <el-table-column label="销量" width="100" sortable="custom" prop="buynum">
          <template slot-scope="scope">{{ scope.row.buy_count }}</template>
        </el-table-column>

        <el-table-column label="操作" :width="params.self_goods === 1 ? 240 : 100" fixed="right">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="selectGoods(scope.row)"
              v-if="scope.row.market_enable === 1"
            >挑选</el-button>
            <el-button
              size="mini"
              v-show="params.self_goods === 1 && scope.row.market_enable === 0"
              type="danger"
              @click="deletez(scope.row)"
            >删除</el-button>
            <el-button
              size="mini"
              type="success"
              plain
              v-if="scope.row.market_enable === 0 && is_ziyou"
              @click="upBatchGoods(scope.row)"
            >上架</el-button>
            <el-button
              size="mini"
              v-if="scope.row.market_enable === 1 && is_ziyou"
              type="danger"
              @click="underGoods(scope.row)"
            >下架</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no"
        :page-size="pageData.page_size"
        :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout"
        background
        :total="pageData.data_total"
      ></el-pagination>
    </en-table-layout>

    <!--添加标签类型-->
    <el-dialog title="选择商品分类" :visible.sync="tagShow" width="600px">
      <div class="tagForm">
        <el-form label-width="180px">
          <el-select v-model="firstValue" placeholder="请选择一级分类" @change="choosefirst">
            <el-option
              v-for="item1 in firstData"
              :key="item1.cat_id"
              :label="item1.name"
              :value="item1.cat_id"
            ></el-option>
          </el-select>

          <el-select
            v-model="secondValue"
            v-if="secondData.length>0"
            placeholder="请选择二级分类"
            @change="choosesecond"
          >
            <el-option
              v-for="item2 in secondData"
              :key="item2.cat_id"
              :label="item2.name"
              :value="item2.cat_id"
            ></el-option>
          </el-select>

          <el-select
            v-model="thirdValue"
            v-if="thirdData.length>0"
            placeholder="请选择三级分类"
            @change="choosethird"
          >
            <el-option
              v-for="item3 in thirdData"
              :key="item3.cat_id"
              :label="item3.name"
              :value="item3.cat_id"
            ></el-option>
          </el-select>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="primary" size="small" @click="tagShow = false">取消</el-button>
        <el-button type="primary" size="small" @click="leaveIsYse">确定</el-button>
      </div>
    </el-dialog>

    <en-gaijia-picker
      :showm="dialogShopShow1"
      @close="close"
      @res="res"
      :shop_type="shopInfo.shop_type"
      @confirm1="handleShopPickerConfirm1"
      :idsList="idsList"
      :allId="pageData.data_total"
    />

    <en-store-picker
      :show="dialogShopShow"
      @close="dialogShopShow = false"
      @confirm="handleShopPickerConfirm"
      @storeType="e => storeType = e"
    />
  </div>
</template>
<script>
import * as API_goods from "@/api/goods";
import * as API_goodsCategory from "@/api/goodsCategory";
import { CategoryPicker } from "@/components";
import { RegExp, Foundation } from "@/../ui-utils";
import * as API_Floor from "@/api/floor";
import SvgIcon from "@/components/SvgIcon";
import { $goodsCategory } from "@/pages/goods/services/goods-category";

import TagJD from "@/assets/goods/tag-jd.png";
import TagJX from "@/assets/goods/tag-jx.png";
import TagZY from "@/assets/goods/tag-zy.png";
import MarketHeader from "@/views/shopGoods/market-header";
import EnTableLayout from "../../../../ui-components/TableLayout/src/main";
import { sendShopTh } from '../../../api/goods'

export default {
  name: "goodsList",
  components: {
    EnTableLayout,
    MarketHeader,
    SvgIcon,
    [CategoryPicker.name]: CategoryPicker
  },
  data() {
    return {
      key_word: "goods_name",
      searchContent: "",
      categoryProxy: $goodsCategory.create(),
      category: "",
      market_header_search: "",
      pageData: {
        page_no: 1,
        page_size: 20,
        data_total: 0
      },
      storeType: null,
      child1: [],
      child: [],
      dialogShopShow1: false,
      dialogShopShow: false,
      aaaaa: "",
      idsList: [],
      /** 列表loading状态 */
      loading: false,

      /** 列表参数 */
      sort1: "",
      sort2: "",
      params: {
        page_no: 1,
        page_size: 20,
        // 以下不是京东馆参数
        high_price: "",
        low_price: "",
        high_mktprice: "",
        low_mktprice: "",
        profit_min: "",
        profit_max: "",
        self_goods: 0,
        // 排序
        sort: "",
        // 京东排序
        sort_type: "",
        category_path: "",
        keyword: "",

        // 京东馆价钱最大值和最小
        min: "",
        max: "",
        cid1: "", //一级分类
        cid2: "" //二级分类
      },

      /** 列表数据 */
      tableData: {},
      /** 店铺信息 */
      shopInfo: this.$store.getters.shopInfo,
      ac: 1,
      categoryListLevel1: [],
      tempList: [],
      shop_id: "",
      pa: {
        set_under_message: ""
      },
      fdList: [],
      // 京东馆商品分类
      JDdepth: 2,
      PTdepth: 3,
      goods_source: 0,
      tagShow: false,
      firstValue: "",
      secondValue: "",
      thirdValue: "",
      firstData: [],
      secondData: [],
      thirdData: [],
      category_id: 0,
      is_ziyou: false,
      areaNum: 1,
      provinceList: [],
      cityList: [],
      countyList: [],
      townList: [],
      areaSelectData: {
        province: "",
        city: "",
        county: "",
        town: "",
        jd_area_state: 0
      },
      quantityStatus: false
    };
  },

  watch: {
    "areaSelectData.province": {
      handler(newValue) {
        this.areaSelectData.city = "";
        this.areaSelectData.county = "";
        this.areaSelectData.town = "";
        if (newValue === "") {
          this.areaNum = 1;
          this.getAreaHandle("province");
        } else {
          this.areaNum = 2;
          this.getAreaHandle("city", { id: newValue });
        }
      },
      immediate: true,
      deep: true
    },
    "areaSelectData.city": {
      handler(newValue) {
        this.areaSelectData.county = "";
        this.areaSelectData.town = "";
        if (this.areaSelectData.province === "") return;
        if (newValue === "") this.areaNum = 2;
        else {
          this.areaNum = 3;
          this.getAreaHandle("county", { id: newValue });
        }
      },
      deep: true
    },
    "areaSelectData.county": {
      handler(newValue) {
        this.areaSelectData.town = "";
        if (this.areaSelectData.city === "") return;
        if (newValue === "") this.areaNum = 3;
        else {
          this.areaNum = 4;
          this.getAreaHandle("town", { id: newValue });
        }
      },
      deep: true
    }
  },

  filters: {},
  mounted() {
    this.market_header_search = this.$route.query.hasOwnProperty("seller_name")
      ? "seller_name"
      : "goods_name";
    this.is_ziyou = this.$route.fullPath.split("/").slice(-1)[0] === "1";
  },
  updated() {
    this.is_ziyou = this.$route.fullPath.split("/").slice(-1)[0] === "1";
  },
  methods: {
    selectChange() {
      console.log(this.category);
      // if (!this.category) return;
      // this.emitParams(2);
    },
    /**
     * 获取区域数据
     * @param type
     * @param data
     */
    getAreaHandle(type, data = {}) {
      if (type === "province") {
        API_goods.getJDProvince().then(res => {
          this.provinceList = this.dataHandle(res.data.result);
        });
      } else if (type === "city") {
        API_goods.getJDCity(data.id).then(res => {
          this.cityList = this.dataHandle(res.data.result);
        });
      } else if (type === "county") {
        API_goods.getJDCounty(data.id).then(res => {
          this.countyList = this.dataHandle(res.data.result);
        });
      } else if (type === "town") {
        API_goods.getJDTown(data.id).then(res => {
          this.townList = this.dataHandle(res.data.result);
        });
      }
    },
    dataHandle(data) {
      let returnData = [];
      const keys = Object.keys(data);
      const values = Object.values(data);
      keys.forEach((key, index) => {
        const data = {
          name: key,
          id: values[index]
        };
        returnData.push(data);
      });
      return returnData;
    },
    // 下架商品
    underGoods(row) {
      this.$confirm("确认下架此商品？", "提示", {
        type: "warning"
      }).then(() => {
        API_goods.underGoods(row.goods_id).then(res => {
          this.$message.success("下架成功");
          this.GET_GoodsList();
        });
      });
    },
    // 上架商品
    upBatchGoods(row) {
      API_goods.upBatchGoods([row.goods_id]).then(res => {
        this.$message.success("上架成功");
        this.GET_GoodsList();
      });
    },
    getRowTagImage(row) {
      switch (row.good_source) {
        case "自有商品":
          // case 0:
          return TagZY;
        case "京东商品":
          // case 1:
          return TagJD;
        case "普通商品":
          // case 2:
          return TagJX;
      }
    },
    leaveIsYse() {
      if (this.firstValue) {
        if (this.secondData.length > 0) {
          if (this.secondValue) {
            if (this.thirdData.length > 0) {
              if (this.thirdValue) {
                this.pa.category_id = this.thirdValue;
                API_goods.sendShop(this.pa).then(res => {
                  this.tagShow = false;
                  this.GET_GoodsList();
                });
              }
            } else {
              this.pa.category_id = this.secondValue;
              API_goods.sendShop(this.pa).then(res => {
                this.tagShow = false;
                this.GET_GoodsList();
              });
            }
          }
        } else {
          this.pa.category_id = this.firstValue;
          API_goods.sendShop(this.pa).then(res => {
            this.tagShow = false;
            this.GET_GoodsList();
          });
        }
      }
    },
    choosefirst() {
      this.secondData = this.firstData.find(item => {
        return item.cat_id === this.firstValue;
      }).children;
      this.secondValue = "";
    },
    choosesecond() {
      this.thirdData = this.secondData.find(item => {
        return item.cat_id === this.secondValue;
      }).children;
      this.thirdValue = "";
    },
    choosethird() {},
    deletez(row) {
      let arr = [row.goods_id];
      this.$confirm("确认删除?", "提示", { type: "warning" }).then(() => {
        API_goods.deleteGoods(row.goods_id, { goods_ids: arr }).then(res => {
          this.$message.success("删除成功");
          this.GET_GoodsList();
        });
      });
    },
    toggleSelection(rows) {
      this.$refs.multipleTable.clearSelection();
    },
    close() {
      this.dialogShopShow1 = false;
    },
    // 重新挑选商城
    res() {
      this.dialogShopShow = true;
      this.dialogShopShow1 = false;
    },
    addShop() {
      this.$router.push("/markets/publish");
    },
    selectGoods(row) {
      this.idsList = [];
      this.idsList.push(row);
      this.dialogShopShow = true;
    },
    selectGoodsAll() {
      this.idsList = this.tempList;
      if (!this.idsList.length) {
        this.$message.error("请勾选商品");
        return;
      }
      this.dialogShopShow = true;
    },
    async handleShopPickerConfirm1(item) {
      this.dialogShopShow1 = false;
      this.pa.shop_price = item.shop_price;
      this.pa.enterprise_price = item.enterprise_price;
      this.pa.shop_goods_status = item.shop_goods_status;
      this.pa.goods_ids = await this.getGoodsId();
      this.pa.goods_source = this.goods_source;
      if (this.storeType === 2){
        API_goods.sendShopTh(this.pa).then(res => {
          this.$message.success("挑选成功");
          this.GET_GoodsList();
        })
      }else {
        API_goods.sendShop(this.pa).then(res => {
          this.$message.success("挑选成功");
          this.GET_GoodsList();
        })
      }
    },
    handleShopPickerConfirm(list) {
      this.dialogShopShow1 = true;
      this.shop_id = list.shop_id;
      this.pa.shop_id = list.shop_id;
    },
    getGoodsId() {
      let arr = [];
      for (let i = 0; i < this.idsList.length; i++) {
        arr.push(this.idsList[i].goods_id);
      }
      return arr;
    },

    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_GoodsList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_GoodsList();
    },
    pxx(name) {
      if (this.sort1 === name) {
        this.sort2 = this.sort2 === "asc" ? "desc" : "asc";
      } else {
        this.sort2 = "asc";
      }
      this.sort1 = name;
      this.params.sort = this.sort1 + "_" + this.sort2;
      this.GET_GoodsList();
    },
    /** 搜索事件触发 */
    searchEvent(data) {
      this.params = {
        ...this.params,
        keyword: data
      };
      this.params.page_no = 1;
      this.params.category_path = this.category;
      switch (this.key_word) {
        case "goods_name":
          this.params.goods_name = this.searchContent;
          break;
        case "seller_name":
          this.params.seller_name = this.searchContent;
          break;
        default:
          break;
      }
      this.GET_GoodsList();
    },
    handleSelectionChange(list) {
      this.tempList = list;
    },
    headerParamChange(params) {
      delete params.keyword;
      this.params = {
        page_no: 1,
        page_size: 20,
        // 以下不是京东馆参数
        high_price: "",
        low_price: "",
        high_mktprice: "",
        low_mktprice: "",
        profit_min: "",
        profit_max: "",
        self_goods: 0,
        // 排序
        sort: "",
        // 京东排序
        sort_type: "",
        category_path: "",
        keyword: "",
        // 京东馆价钱最大值和最小
        min: "",
        max: "",
        ...params
      };
      this.areaSelectData = {
        province: "",
        city: "",
        county: "",
        town: "",
        jd_area_state: 0
      },
      this.key_word = "goods_name";
      this.searchContent = "";
      this.category = "";
      this.is_ziyou = this.$route.fullPath.split("/").slice(-1)[0] === "1";
      this.GET_GoodsList();
    },
    handleSortChange({ prop, order }) {
      if (order) {
        this.params.sort = `${prop}_${order.includes("asc") ? "asc" : "desc"}`;
      } else {
        this.params.sort = "";
      }
      this.GET_GoodsList();
    },
    GET_GoodsList() {
      if (this.params.self_goods === 2 && this.areaSelectData.province !== "") {
        let jd_area = "" + this.areaSelectData.province + "_";
        if (this.areaSelectData.city !== "")
          jd_area += this.areaSelectData.city + "_";
        else jd_area += "0_";
        if (this.areaSelectData.county !== "")
          jd_area += this.areaSelectData.county + "_";
        else jd_area += "0_";
        if (this.areaSelectData.town !== "")
          jd_area += this.areaSelectData.town;
        else jd_area += "0";
        this.params.jd_area = jd_area;
        this.params.jd_area_state = this.areaSelectData.jd_area_state;
        this.quantityStatus = true;
      } else if (this.params.jd_area_state) {
        delete this.params.jd_area;
        delete this.params.jd_area_state;
        this.quantityStatus = false;
      }
      let params = {
        ...this.params,
        market_enable: 1
      };
      if (this.is_ziyou) {
        delete params["market_enable"];
      }
      this.loading = true;
      this.goods_source = 0;

      params.goods_source = this.params.self_goods === 2 ? 1 : 0;
      params.category_path = params.path ? params.path : params.category_path;

      delete params.path;

      // 导航index == -1 时为'所有'标签，此时筛选出所有的商品，不区分来源
      // if (this.index === -1) {
      //   delete params.self_goods
      //   delete params.goods_source
      // }

      // if (params.category_path) {
      //   // params.goods_source = "";
      //   // params["goods_name"] = "";
      //   // params["seller_name"] = "";
      //   // params["self_goods"] = "";
      // } else if (params["goods_name"] || params["seller_name"]) {
      //   if (params["goods_name"]) {
      //     params["seller_name"] = "";
      //   } else {
      //     params["goods_name"] = "";
      //   }
      //   // params.category_path = "";
      //   // params.goods_source = "";
      //   // params["self_goods"] = "";
      // } else {
      //   params["goods_name"] = "";
      //   params["seller_name"] = "";
      //   params.category_path = "";
      // }
      if (params["goods_name"]) {
        params["seller_name"] = "";
      } else {
        params["goods_name"] = "";
      }

      if (params.goods_source === 1) {
        params.is_auth = 1;
      }
      if (params.self_goods === 0) {
        params.goods_source = "";
        params.self_goods = "";
      }
      API_goods.getGoodsListq(params).then(response => {
        this.loading = false;
        this.tableData = response.data;
        this.pageData = response;
        // 重新绘制表格
        this.$refs.tableLayout.$refs.table.doLayout();
      });
    }
  }
};
</script>
<style lang="scss">
.el-row {
  width: 100%;
}
.address-border {
  width: 100%;
  padding: 10px;
  border: 1px solid #e9ebf0;
  border-radius: 5px;
}
.private-goods-page {
}
</style>
